var EventValidationsService = require('./eventsValidations-service');

module.exports = function DynamicService() {
    var that = this;
    var eventValidationsService = new EventValidationsService();

    this.isServiceInsideWindow = function isServiceInsideWindow(window, chosenService) {
        var result = false;

        if(!!window && that.isValidServices(window.activeServices) && !!chosenService && !!chosenService.serviceId){

            window.activeServices.forEach(function (service) {
                if(service.serviceId === chosenService.serviceId){
                    result = true;
                    return;
                }
            });
        }

        return result;
    };

    this.isWindow = function isWindow(event) {
        return !!event && that.isValidServices(event.activeServices);
    };

    this.isValidServices = function isValidServices(services) {
        var result = true;

        if(!!services  && services.length > 0){
            services.forEach(function (service) {
                if(!service.serviceId || !service.name || !service.durationInMinutes){
                    result= false;
                }
            })
        }
        else{
            result = false;
        }

        return result;
    };

    this.isValidServicesToInsert = function isValidServicesToInsert(services) {
        var result = true;

        if(!!services  && services.length > 0){
            services.forEach(function (service) {
                if(!service.name || !service.durationInMinutes){
                    result= false;
                }
            })
        }
        else{
            result = false;
        }

        return result;
    };

    this.getValidRanges = function getValidRanges(eventWindow) {
        var validRanges = [];

        if(!!eventWindow && eventValidationsService.isValidEventTimes(eventWindow.startTime, eventWindow.endTime)){
            var origStartTime = eventWindow.startTime;
            var origEndTime = eventWindow.endTime;
            validRanges = [{startTime: origStartTime, endTime: origEndTime}];
            var innerEvents = eventWindow.events;

            if(!!innerEvents && innerEvents.length > 0){
                var innerEventStartTime= {};
                var innerEventEndTime= {};
                innerEvents.forEach(function(innerEvent){
                    if(eventValidationsService.isValidEventTimes(innerEvent.startTime, innerEvent.endTime)){
                        innerEventStartTime = innerEvent.startTime;
                        innerEventEndTime = innerEvent.endTime;
                        updateValidRanges(innerEventStartTime, innerEventEndTime, validRanges, origStartTime);
                    }
                });
            }
        }


        return validRanges;
    };

    this.getServiceRanges = function getServiceRanges(chosenService, ranges, breakTimeInMinutes, allowFirstRangeBreak) {
        var result = [];
        if(!!ranges && ranges.length > 0 && !!chosenService && typeof chosenService.durationInMinutes === "number" && chosenService.durationInMinutes > 0){
            ranges.forEach(function (range) {
                if(eventValidationsService.isValidEventTimes(range.startTime, range.endTime)){
                    splitRangeUpdateResult(range, result, chosenService.durationInMinutes, breakTimeInMinutes > 0 ? breakTimeInMinutes : 0, allowFirstRangeBreak);
                }
            });
        }

        return result;

    };

    this.isValidSettingsCalPrefs = function isValidSettingsCalPrefs(calendarPref, events, windows) {
        var response = {
            success: false,
            reasonCode: -1,
            reason: "unknown"
        };

        try{
            if(!isValidPrefObj(calendarPref)){
                response.reasonCode = 400;
                response.reason = "invalid calendarPref object";
                return response;
            }

            if(!this.isValidEventCalPrefs(calendarPref, events)){
                response.reasonCode = 401;
                response.reason = "at least one invalid event";
                return response;
            }

            // if(!this.isValidWindowCalPrefs(calendarPref, windows)){
            //     response.reasonCode = 402;
            //     response.reason = "at least one invalid window";
            //     return response;
            // }

            response.success = true;
            response.reasonCode = 0;
            response.reason = "valid";
            return response;
        }
        catch (e) {
            return response;
        }

    };

    function eventBetweenTimes(eventStartTime, eventEndTime, minHour, maxHour) {
        return  eventStartTime.getHours() < minHour ||
            eventStartTime.getHours() > maxHour ||
            eventEndTime.getHours() > maxHour ||
            eventEndTime.getHours() === maxHour &&  eventEndTime.getMinutes() > 0 ||
            eventEndTime.getHours() < minHour && !isEndTimeMidnight(eventStartTime, eventEndTime);
    }

    function isEndTimeMidnight(eventStartTime, eventEndTime) {
        return eventEndTime.getHours() === 0 && eventStartTime.addDays(1).getDate() === eventEndTime.getDate();
    }

    function eventInHiddenDay(eventDayOfWeek, hiddenDays) {
        return hiddenDays.includes(eventDayOfWeek);
    }

    this.isValidEventCalPrefs = function isValidEventCalPrefs(calendarPref, events) {
        var result = true;

        if(!Array.isArray(events)){
            return false;
        }

        if(!isValidPrefObj(calendarPref)){
            return false;
        }

        var minHour = parseInt(calendarPref.minTime.split(":")[0]);
        var maxHour = parseInt(calendarPref.maxTime.split(":")[0]);
        events.forEach(function (event) {
            if(!isValidEventObj(event)){
                result = false;
            }
            if(result === true){
                var eventStartTime = new Date(event.startTime);
                var eventEndTime = new Date(event.endTime);
                var eventDayOfWeek = parseInt(eventStartTime.getDay());
                if(eventBetweenTimes(eventStartTime, eventEndTime, minHour, maxHour) || eventInHiddenDay(eventDayOfWeek, calendarPref.hiddenDays)){
                    result = false;
                }
            }
        });

        return result;

    };

    this.isValidWindowCalPrefs = function isValidWindowCalPrefs(calendarPref, windows) {
        var result = true;

        if(!Array.isArray(windows)){
            return false;
        }

        if(!isValidPrefObj(calendarPref)){
            return false;
        }

        var minHour = parseInt(calendarPref.minTime.split(":")[0]);
        var maxHour = parseInt(calendarPref.maxTime.split(":")[0]);
        windows.forEach(function (window) {
            if(!isValidWindowObj(window)){
                result = false;
            }
            if(result === true){
                if( (window.durationInMinutes / 60) > (maxHour - minHour) ){
                    result = false;
                }
            }
        });

        return result;
    };

    function isValidWindowObj(window) {
        return !!window
            && !!window.durationInMinutes
            && !!window.windowName;
    }


    function isValidEventObj(event) {
        return !!event
            && !!event.startTime
            && !!event.endTime;
    }

    function isValidPrefObj(calendarPref) {
        if(!!calendarPref && !!calendarPref.maxTime && !!calendarPref.minTime && !!calendarPref.hiddenDays && Array.isArray(calendarPref.hiddenDays)){
            return true;
        }

        return false;
    }

    function splitRangeUpdateResult(range, result, serviceDurationInMinutes, breakTimeInMinutes, allowFirstRangeBreak) {
        var rangeSize = (range.endTime - range.startTime) / 60000;
        var actualDuration = serviceDurationInMinutes + breakTimeInMinutes;
        if(actualDuration > rangeSize){
            return;
        }

        var latestEndTime;
        var minutesAddFactor = serviceDurationInMinutes * 60000;
        var breakTimeMinutesFactor = breakTimeInMinutes * 60000;
        var i = 0;
        var currIterationEndTime;

        do{
            if(i === 0){
                var breakTimeToAdd = (range.allowBreakOnRangeBegin === true || allowFirstRangeBreak === true) ? breakTimeMinutesFactor : 0;
                currIterationEndTime = range.startTime + minutesAddFactor +  breakTimeToAdd;
                result.push({startTime:range.startTime + breakTimeToAdd, endTime: currIterationEndTime});
            }
            else{
                currIterationEndTime = latestEndTime + minutesAddFactor + breakTimeMinutesFactor;
                result.push({startTime:latestEndTime + breakTimeMinutesFactor, endTime: currIterationEndTime});
            }
            latestEndTime = currIterationEndTime;
            i++;
        } while ( (latestEndTime + minutesAddFactor + breakTimeMinutesFactor) <= range.endTime);

        if(range.allowBreakOnRangeEnd){
            if(range.endTime < result[result.length - 1].endTime + breakTimeMinutesFactor){
                result.pop();
            }
        }

    }

    function updateValidRanges(innerEventStartTime, innerEventEndTime, validRanges, origStartTime){
        validRanges.forEach(function (range) {
            var allowBreakOnRangeBegin = true;
            if(range.startTime === origStartTime){
                allowBreakOnRangeBegin = false
            }
            var rangeDate = new Date(range.startTime);
            var innerEventDate = new Date(innerEventStartTime);
            //Check if inner event is inside current range
            if(rangeDate.getDate() === innerEventDate.getDate() && range.startTime < innerEventStartTime && range.endTime > innerEventEndTime){
                //remove current range
                validRanges.splice(validRanges.indexOf(range), 1);
                validRanges.push({startTime: range.startTime, endTime: innerEventStartTime, allowBreakOnRangeBegin: allowBreakOnRangeBegin});
                validRanges.push({startTime: innerEventEndTime, endTime: range.endTime, allowBreakOnRangeBegin: true, allowBreakOnRangeEnd: true});
            }
            else if(rangeDate.getDate() === innerEventDate.getDate() && range.startTime === innerEventStartTime && range.endTime > innerEventEndTime){
                //remove current range
                validRanges.splice(validRanges.indexOf(range), 1);
                validRanges.push({startTime: innerEventEndTime, endTime: range.endTime, allowBreakOnRangeBegin: true, allowBreakOnRangeEnd: true});
            }
            else if(rangeDate.getDate() === innerEventDate.getDate() && range.startTime < innerEventStartTime && range.endTime === innerEventEndTime){
                //remove current range
                validRanges.splice(validRanges.indexOf(range), 1);
                validRanges.push({startTime: range.startTime, endTime: innerEventStartTime, allowBreakOnRangeBegin: allowBreakOnRangeBegin, allowBreakOnRangeEnd: false});
            }
            else if(rangeDate.getDate() === innerEventDate.getDate() && range.startTime === innerEventStartTime && range.endTime === innerEventEndTime){
                //remove current range
                validRanges.splice(validRanges.indexOf(range), 1);
            }
        });
    }
};
