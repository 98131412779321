var DynamicService = require('./services/dynamic-service'),
    EventValidationsService = require('./services/eventsValidations-service'),
    dynamicService = new DynamicService(),
    eventValidationsService = new EventValidationsService(),
    formatter = require('./common/formatter');

CommonUtils =
    { dynamicService:
        {isWindow: function isWindow(event) {
            return dynamicService.isWindow(event);
        },
        isValidServices: function isValidServices(services) {
            return dynamicService.isValidServices(services);
        },
        isValidServicesToInsert: function isValidServicesToInsert(services) {
            return dynamicService.isValidServicesToInsert(services);
        },
        isServiceInsideWindow: function isServiceInsideWindow(window, chosenService) {
            return dynamicService.isServiceInsideWindow(window, chosenService);
        },
        getValidRanges: function getValidRanges(eventWindow) {
            return dynamicService.getValidRanges(eventWindow);
        },
        getServiceRanges: function getServiceRanges(chosenService, ranges, breakTimeInMinutes, allowFirstRangeBreak) {
            return dynamicService.getServiceRanges(chosenService, ranges, breakTimeInMinutes, allowFirstRangeBreak) ;
        },
        isValidSettingsCalPrefs: function isValidSettingsCalPrefs(calendarPref, events, windows) {
            return dynamicService.isValidSettingsCalPrefs(calendarPref, events, windows) ;
        },
        isValidEventCalPrefs: function isValidEventCalPrefs(calendarPref, events) {
            return dynamicService.isValidEventCalPrefs(calendarPref, events) ;
        },
        isValidWindowCalPrefs: function isValidWindowCalPrefs(calendarPref, windows) {
            return dynamicService.isValidWindowCalPrefs(calendarPref, windows) ;
        }},
    eventValidationService:
        {isValidEventLength: function isValidEventLength(startTime, endTime)  {
            return eventValidationsService.isValidEventLength(startTime, endTime);
        },
        isEventOverlaps: function isEventOverlaps(eventToCreate, existingEvents)  {
            return eventValidationsService.isEventOverlaps(eventToCreate, existingEvents);
        },
        isValidEventTimes: function isValidEventTimes(startTime, endTime)  {
            return eventValidationsService.isValidEventTimes(startTime, endTime);
        },
        getEventOverlaps: function getEventOverlaps(eventToCreate, existingEvents) {
            return eventValidationsService.getEventOverlaps(eventToCreate, existingEvents)
        }},
    formatter:
        {
            bcryptCompare: function bcryptCompare(clearText, crypt) {
                return formatter.bcryptCompare(clearText, crypt);
            },
            getTimeZoneOffset: function getTimeZoneOffset(timeZoneStr){
                return formatter.getTimeZoneOffset(timeZoneStr);
            }
        }
};

module.exports = CommonUtils;
