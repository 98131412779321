var formatter = {},
    moment = require('moment-timezone'),
    bcrypt = require('bcrypt-nodejs');

formatter.bcryptCompare = function bcryptCompare(clearText, crypt){
    if (!!crypt && !!clearText) {
        return bcrypt.compareSync(clearText, crypt);
    }

    return false;
}

formatter.getTimeZoneOffset = function getTimeZoneOffset(timeZoneStr){
    let hourOffset =  moment(new Date()).tz(timeZoneStr)._offset / 60;
    let offsetSign = hourOffset >= 0 ? "+" : "";
    return offsetSign + hourOffset;

}

// padding the given number with zeros (length is the desired length with the zeros)
formatter.zeroPadding = function zeroPadding(str, length) {
    str = str || "";
    if (!!str) {
        str = String(str);
        length = length || 0;
        while (str.length < length) {
            str = '0' + str;
        }
    }
    return str;
};

// generate new GUID
formatter.GUID = (function() {
    function s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    }
    return function() {
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
            s4() + '-' + s4() + s4() + s4();
    };
})();

formatter.GenerateConfirmationNum = (function() {
    // generate random int between 1-9
    function getRandomInt() {
        return Math.floor(Math.random() * (9-1)) + 1; //The maximum is exclusive and the minimum is inclusive
    }
    return function() {
        return getRandomInt().toString()
            + getRandomInt().toString()
            + getRandomInt().toString()
            + getRandomInt().toString();
    };
})();

if (typeof module !== "undefined") {
    module.exports = formatter;
}

if (typeof window !== "undefined") {
    window.formatter = formatter;
}
