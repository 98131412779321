var moment = require('moment');

module.exports = function EventValidationsService() {
    var that = this;

    this.isValidEventTimes = function isValidEventTimes(startTime, endTime) {
        var startDate = new Date(startTime);
        var endDate = new Date(endTime);
        return !!startTime
            && !!endTime
            && endTime - startTime > 0
            && startDate.getTime() > 0
            && endDate.getTime() > 0;
    };

    this.isValidEventLength = function isValidEventLength(startTime, endTime) {
        if (!!startTime && !!endTime && that.isValidEventTimes(startTime, endTime)) {
            var endDate = moment(endTime);
            var startDate = moment(startTime);
            return endDate.diff(startDate, 'minutes') >= 10;
        }
        return false;
    };

    this.isEventOverlaps = function isEventOverlaps(eventToCreate, existingEvents) {
        var result = false;
        if (!!eventToCreate && that.isValidEventTimes(eventToCreate.startTime, eventToCreate.endTime) && !!existingEvents && existingEvents.length > 0) {
            existingEvents.forEach(function (existingEvent) {
                if ((that.isValidEventTimes(existingEvent.startTime, existingEvent.endTime)) &&
                    ((existingEvent.startTime < eventToCreate.startTime && existingEvent.endTime > eventToCreate.startTime) ||
                    (existingEvent.startTime <= eventToCreate.startTime && existingEvent.endTime >= eventToCreate.endTime) ||
                    (existingEvent.startTime > eventToCreate.startTime && existingEvent.startTime < eventToCreate.endTime) ||
                    (existingEvent.endTime > eventToCreate.startTime && existingEvent.endTime < eventToCreate.endTime))) {
                        result = true;
                        return;
                }
            });
        }

        return result;
    };

    this.getEventOverlaps = function getEventOverlaps(eventToCreate, existingEvents) {
        var result = [];
        if (!!eventToCreate && that.isValidEventTimes(eventToCreate.startTime, eventToCreate.endTime) && !!existingEvents && existingEvents.length > 0) {
            existingEvents.forEach(function (existingEvent) {
                if ((that.isValidEventTimes(existingEvent.startTime, existingEvent.endTime)) &&
                    ((existingEvent.startTime < eventToCreate.startTime && existingEvent.endTime > eventToCreate.startTime) ||
                    (existingEvent.startTime <= eventToCreate.startTime && existingEvent.endTime >= eventToCreate.endTime) ||
                    (existingEvent.startTime > eventToCreate.startTime && existingEvent.startTime < eventToCreate.endTime) ||
                    (existingEvent.endTime > eventToCreate.startTime && existingEvent.endTime < eventToCreate.endTime))) {
                        result.push(existingEvent)
                }
            });
        }

        return result;
    }

};
